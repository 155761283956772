<template>
  <div class="container" ref="container">
    <ul class="msg-list">
      <template v-if="!init || msgBoxData.length">
        <li
          class="msg-item ui-btn" v-for="(row, key) in msgBoxData"
          :key="key"
          @click="gotoRoom(row)"
        >
          <div class="msg-info">
            <div class="profile ui-bg-img" :style="{ backgroundImage: `url(${$lib.cdnUrl(userData.category !== 'client' ? row.userImgUrl : row.targetUserImgUrl)})` }"></div>
            <div class="name ui-ellipsis">{{userData.category !== 'client' ? row.userName : row.targetTeamName}}</div>
            <div class="badge-new" v-if="isNew(row)">N</div>
            <div class="date">{{moment(Number(row.chatDate) * 1000).format('YY.MM.DD HH:mm')}}</div>
          </div>
          <div class="last-msg" :class="{ 'target': row.sendUseridx !== userData.useridx }">{{row.msg}}</div>
          <div class="ui-border-line ui-mt-2 ui-mb-2"></div>
        </li>
      </template>
      <blank-list
        v-else
        :text="emptyText"
      />
    </ul>
  </div>
</template>

<script>
import BlankList from '@/components/blank/BlankList'

export default {
  components: {
    BlankList,
  },
  data() {
    return {
      msgBoxData: [],
      init: false,
      history: {
        exit: false,
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    emptyText() {
      return this.isClient
        ? `개설된 채팅방이 없습니다.\n결제(섭외)가 완료되면\n채팅으로 상세 상담을\n받을 수 있어요!`
        : `결제(섭외)가 완료되면\n채팅으로 상세 협의를 시작하세요!\n(MY의뢰>결제내역>채팅하기)`
    },
  },
  created() {
    this.$eventBus.$on('refresh', route => {
      if (route.path === '/message/list') {
        this.$nextTick()
          .then(() => {
            this.$refs?.container?.scrollTo?.({ top: 0 })
          })
          .then(() => {
            this.getList()
          })
      }
    })
  },
  mounted() {
    this.getList()
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      Vue.$store.commit('setGnbLeftBtnCb', () => {
        Vue.$router.push('/home')
      })
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.$store.state.history.back) {
        this.$runToApp({
          androidCb: () => {
            if (!this.history.exit) {
              this.$toast('뒤로 버튼을 한번 더 누르시면 종료됩니다.')
              this.history.exit = true
              setTimeout(() => (this.history.exit = false), 2000)
            } else {
              location.href = 'hbscheme://appExit'
            }
            next(false)
          },
          iosCb: () => next(false),
          webCb: () => next(false),
        })
      } else {
        this.$store.commit('setGnbLeftBtnCb', false)
        next()
      }
    })
  },
  methods: {
    isNew(row) {
      // const viewMsgUid = this.$store.state.user.newMsgData.viewMsgUid
      if (row.sendUseridx == this.userData.useridx) return false
      return row.viewMsgUid < row.lastMsgUid
    },
    getList() {
      const req = {
        method: 'get',
        url: `/chat/msgBox`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let msgBoxData = data.response.msgBoxData || []
            this.msgBoxData = msgBoxData

            let readAllMsg = true
            let lastMsgUidArr = msgBoxData.map(row => {
              // sidemenu에서 new 표시를 위한 로직
              if (this.isNew(row)) readAllMsg = false
              return row.lastMsgUid
            })
            let lastMsgUid = Math.max(...lastMsgUidArr)
            this.$store.commit('setMsgUid', {
              mode: 'last',
              msgUid: lastMsgUid,
            })
            this.$store.commit('setReadAllMsg', readAllMsg)
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
        })
    },
    gotoRoom(row) {
      if (this.$lib.execNgWordProcess(this.$store)) return

      const url = {
        path: `/message/${row.offerUid}/${row.targetUseridx}`,
      }
      if (!this.isClient) {
        url.query = { ttn: row.targetTeamName }
      }
      this.$router.push(url)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.msg-list {
  .msg-item {
    .msg-info {
      overflow: hidden;
      display: flex;

      .profile {
        margin-right: 1rem;
        float: left;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #efefef;
      }
      .name {
        float: left;
        font-size: 1.8rem;
        color: #5a5a5a;
        line-height: 5rem;
        flex: 1;
      }
      .date {
        float: right;
        color: #a2a2a2;
        font-size: 1.3rem;
        line-height: 5rem;
      }
    }
    .last-msg {
      position: relative;
      margin-top: 0.7rem;
      margin-left: 6rem;
      color: #000;
      font-size: 1.8rem;
      line-height: 2.2rem;
      min-height: 4.2rem;
      padding: 1rem 1.8rem;
      border: 1px solid #f0f3f9;
      border-radius: 0.5rem;
      background-color: #f0f3f9;
      // 텍스트 말줄임표
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:not(.target) {
        @include tri-right(8, 4, -16);
        background-color: #f0f3f9;
      }
      &.target {
        @include tri-top(10, -20, 20);
      }
    }
    .badge-new {
      float: left;
      margin: 1.5rem 1rem 0;
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.8rem;
      border-radius: 50%;
      // background-color: #a059b6;
      background-color: #ff8f9f;
      color: #fff;
      font-size: 1rem;
      text-align: center;
      font-weight: 400;
    }
  }
}
</style>
