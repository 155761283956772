var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _c(
      "ul",
      { staticClass: "msg-list" },
      [
        !_vm.init || _vm.msgBoxData.length
          ? _vm._l(_vm.msgBoxData, function(row, key) {
              return _c(
                "li",
                {
                  key: key,
                  staticClass: "msg-item ui-btn",
                  on: {
                    click: function($event) {
                      return _vm.gotoRoom(row)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "msg-info" }, [
                    _c("div", {
                      staticClass: "profile ui-bg-img",
                      style: {
                        backgroundImage:
                          "url(" +
                          _vm.$lib.cdnUrl(
                            _vm.userData.category !== "client"
                              ? row.userImgUrl
                              : row.targetUserImgUrl
                          ) +
                          ")"
                      }
                    }),
                    _c("div", { staticClass: "name ui-ellipsis" }, [
                      _vm._v(
                        _vm._s(
                          _vm.userData.category !== "client"
                            ? row.userName
                            : row.targetTeamName
                        )
                      )
                    ]),
                    _vm.isNew(row)
                      ? _c("div", { staticClass: "badge-new" }, [_vm._v("N")])
                      : _vm._e(),
                    _c("div", { staticClass: "date" }, [
                      _vm._v(
                        _vm._s(
                          _vm
                            .moment(Number(row.chatDate) * 1000)
                            .format("YY.MM.DD HH:mm")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "last-msg",
                      class: {
                        target: row.sendUseridx !== _vm.userData.useridx
                      }
                    },
                    [_vm._v(_vm._s(row.msg))]
                  ),
                  _c("div", { staticClass: "ui-border-line ui-mt-2 ui-mb-2" })
                ]
              )
            })
          : _c("blank-list", { attrs: { text: _vm.emptyText } })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }